<template>
    <v-container fluid class="px-8">
        <!-- 机构角色列表 -->
        <v-data-table
            :headers="headers"
            :items="datas"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 row-pointer mt-5"
            :hide-default-footer="datas.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
        >
            <template v-slot:item.role_name="{ item }">
                <div class="font-weight-medium">
                    <div class="active-name">
                        {{ item.role_name != "" ? item.role_name : "角色" }}
                    </div>
                </div>
            </template>
            <template v-slot:item.operate="{}">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2 darken-2"
                            icon
                            height="32"
                            width="32"
                            outlined
                            elevation="2"
                            x-small
                            color="purple"
                            disabled
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑角色</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2 darken-2"
                            icon
                            height="32"
                            width="32"
                            outlined
                            elevation="2"
                            x-small
                            color="red darken-1"
                            disabled
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除角色</span>
                </v-tooltip>
            </template>
        </v-data-table>

    </v-container>
</template>

<script>
import { getAdminRoleList } from "@/api/admin";

export default {
    name: "RoleList",
    data() {
        return {
            headers: [
                { text: "角色", value: "role_name", align: "left", sortable: false, },
                { text: "描述", value: "desc", align: "left", sortable: false },
                { text: "操作", value: "operate",  align: "center", sortable: false, width: 130 },
            ],
            datas: [],
        };
    },
    activated() {
        this.loadData();
    },
    methods: {
        loadData() {
            getAdminRoleList().then((response) => {
                // console.log(response.data);
                this.datas = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
    },
    components: {
    }
};
</script>

<style lang="scss" scoped>
.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.avatar {
    height: 45px;
    width: 45px;
    img {
        height: 100%;
        width: 100%;
    }
}
</style>